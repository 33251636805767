import React from "react";
import Layout from "src/components/layout/Layout";
import Patrons from "src/components/layout/Patrons/Patrons";
import PrivacyPolicyTalesDSContent from "src/components/pages/privacy-policy-tales-ds/PrivacyPolicyTalesDSContent";
import Seo from "src/components/seo";

const index = () => {
  return (
    <Layout>
      <Seo />
      <PrivacyPolicyTalesDSContent />
      <Patrons />
    </Layout>
  );
};

export default index;
