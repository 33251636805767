import React from "react";
import * as styles from "./PrivacyPolicyTalesDSContent.module.scss";

export default function PrivacyPolicyTalesDSContent() {
  return (
    <section className={styles.Wrapper}>
      <div className={styles.HeaderWrapper}>
        <h2>Polityka prywatności strony internetowej aplikacji Tales</h2>
      </div>
      <article className={styles.Article}>
        <div>
          <p>
            1. Dla Właściciela niniejszej strony internetowej, ochrona danych
            osobowych Użytkowników jest sprawą najwyższej wagi. Dokłada on ogrom
            starań, aby Użytkownicy czuli się bezpiecznie, powierzając swoje
            dane osobowe w trakcie korzystania ze strony internetowej.
          </p>
        </div>

        <div>
          <p>
            2. Użytkownik to osoba fizyczna, osoba prawna albo jednostka
            organizacyjna nieposiadająca osobowości prawnej, której ustawa
            przyznaje zdolność prawną, korzystająca z usług elektronicznych
            dostępnych w ramach strony internetowej.
          </p>
        </div>

        <div>
          <p>
            3. Niniejsza polityka prywatności wyjaśnia zasady i zakres
            przetwarzania danych osobowych Użytkownika, przysługujące mu prawa,
            jak też obowiązki administratora tych danych, a także informuje o
            używaniu plików cookies.
          </p>
        </div>

        <div>
          <p>
            4. Administrator stosuje najnowocześniejsze środki techniczne i
            rozwiązania organizacyjne, zapewniające wysoki poziom ochrony
            przetwarzanych danych osobowych oraz zabezpieczenia przed dostępem
            osób nieupoważnionych.
          </p>
        </div>

        <div>
          <h3>I. ADMINISTRATOR DANYCH OSOBOWYCH</h3>
          <p>
            Administratorem danych osobowych jest Blesu sp. z o.o. z siedzibą
            przy: ul. I. Prądzyńskiego 34, 05-080 Izabelin, wpisany do rejestru
            przedsiębiorców prowadzonego przez Sąd Rejonowy dla M. st. Warszawy
            w Warszawie XIV wydział Krajowego rejestru Sądowego pod nr KRS
            701705, NIP: 1182156865 (zwany dalej: „Właściciel").
          </p>
        </div>

        <div>
          <h3>II. CEL PRZETWARZANIA DANYCH OSOBOWYCH</h3>
          <p>1. Administrator przetwarza dane osobowe Użytkownika w celu:</p>
          <ul>
            <li>
              Świadczenia usług w aplikacji Tales na systemy iOS, Andorid oraz
              przeglądarkowej wersji w formie panelu administracyjnego.
            </li>
          </ul>
          <p>
            2. Oznacza to, że dane te są potrzebne w szczególności do
            zarejestrowania się na stronie internetowej.
          </p>
          <p>
            3. Użytkownik może również wyrazić zgodę na otrzymywanie informacji
            o nowościach i promocjach, co spowoduje, że administrator będzie
            również przetwarzać dane osobowe w celu przesyłania Użytkownikowi
            informacji handlowych dotyczących m.in. nowych produktów lub usług,
            promocji czy wyprzedaży.
          </p>
          <p>
            4. Dane osobowe są również przetwarzane w ramach wypełnienia
            prawnych obowiązków ciążących na administratorze danych oraz
            realizacji zadań w interesie publicznym m.in. do wykonywania zadań
            związanych z bezpieczeństwem i obronnością lub przechowywaniem
            dokumentacji podatkowej.
          </p>
          <p>
            5. Dane osobowe mogą być również przetwarzane w celach marketingu
            bezpośredniego produktów, zabezpieczenia i dochodzenia roszczeń lub
            ochrony przed roszczeniami Użytkownika lub osoby trzeciej, jak
            również marketingu usług i produktów podmiotów trzecich lub
            marketingu własnego, niebędącego marketingiem bezpośrednim.
          </p>
        </div>

        <div>
          <h3>III. RODZAJ DANYCH</h3>
          <p>
            1. Administrator przetwarza następujące dane osobowe, których
            podanie jest niezbędne do:
          </p>
          <p>a. zarejestrowania się na stronie internetowej:</p>
          <ul>
            <li>imię i nazwisko; </li>
            <li>adres e-mail;</li>
            <li>numer telefonu;</li>
          </ul>

          <p>b. Dane podawane przez Użytkownika opcjonalnie:</p>
          <ul>
            <li>data urodzenia;</li>
          </ul>
        </div>

        <div>
          <h3>IV. PODSTAWA PRAWNA PRZETWARZANIA DANYCH OSOBOWYCH</h3>
          <p>
            1. Dane osobowe są przetwarzane zgodnie z przepisami Rozporządzenia
            Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
            2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
            danych osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
            danych), OJ L 119, 4.5.2016, p. 1–88, dalej zwane: „rozporządzenie
            RODO".
          </p>
          <p>
            2. Administrator przetwarza dane osobowe wyłącznie po uprzednim
            uzyskaniu zgody Użytkownika, wyrażonej w chwili rejestracji na
            stronie internetowej.
          </p>
          <p>
            3. Wyrażenie zgody na przetwarzanie danych osobowych jest całkowicie
            dobrowolne, jednakże brak jej udzielenia uniemożliwia
            zarejestrowanie się na stronie internetowej.
          </p>
        </div>

        <div>
          <h3>V. PRAWA PRZYSŁUGUJĄCE UŻYTKOWNIKOWI</h3>
          <p>
            1. Użytkownik może w każdej chwili zażądać od administratora
            informacji o zakresie przetwarzania danych osobowych.
          </p>
          <p>
            2. Użytkownik może w każdej chwili zażądać poprawienia bądź
            sprostowania swoich danych osobowych. Użytkownik może to również
            zrobić samodzielnie, po zalogowaniu się na swoje konto.
          </p>
          <p>
            3. Użytkownik może w każdej chwili wycofać swoją zgodę na
            przetwarzanie jego danych osobowych, bez podawania przyczyny.
            Żądanie nieprzetwarzania danych może dotyczyć wskazanego przez
            Użytkownika konkretnego celu przetwarzania np. wycofanie zgody na
            otrzymywanie informacji handlowych bądź dotyczyć wszystkich celów
            przetwarzania danych. Wycofanie zgody co do wszystkich celów
            przetwarzania spowoduje, że konto Użytkownika zostanie usunięte ze
            strony internetowej, wraz ze wszystkimi wcześniej przetwarzanymi
            przez administratora danymi osobowymi Użytkownika. Wycofanie zgody
            nie wpłynie na już dokonane czynności.
          </p>
          <p>
            4. Użytkownik może w każdej chwili żądać, bez podawania przyczyny,
            aby administrator usunął Jego dane. Żądanie usunięcia danych nie
            wpłynie na dotychczas dokonane czynności. Usunięcie danych oznacza
            jednoczesne usunięcie konta Użytkownika, wraz ze wszystkimi
            zapisanymi i przetwarzanymi do tej pory przez administratora danymi
            osobowymi.
          </p>
          <p>
            5. Użytkownik może w każdej chwili wyrazić sprzeciw przeciwko
            przetwarzaniu danych osobowych, zarówno w zakresie wszystkich
            przetwarzanych przez administratora danych osobowych Użytkownika,
            jak również jedynie w ograniczonym zakresie np. co do przetwarzania
            danych w konkretnie wskazanym celu. Sprzeciw nie wpłynie na
            dotychczas dokonane czynności. Wniesienie sprzeciwu spowoduje
            usunięcie konta Użytkownika, wraz ze wszystkimi zapisanymi i
            przetwarzanymi do tej pory, przez administratora, danymi osobowymi.
          </p>
          <p>
            6. Użytkownik może zażądać ograniczenia przetwarzania danych
            osobowych, czy to przez określony czas, czy też bez ograniczenia
            czasowego, ale w określonym zakresie, co administrator będzie
            obowiązany spełnić. Żądanie to nie wpłynie na dotychczas dokonane
            czynności.
          </p>
          <p>
            7. Użytkownik może zażądać, aby administrator przekazał innemu
            podmiotowi, przetwarzane dane osobowe Użytkownika. Powinien w tym
            celu napisać prośbę do administratora, wskazując, jakiemu podmiotowi
            (nazwa, adres) należy przekazać dane osobowe Użytkownika oraz jakie
            konkretnie dane Użytkownik życzy sobie, żeby administrator
            przekazał. Po potwierdzeniu przez Użytkownika swojego życzenia,
            administrator przekaże, w formie elektronicznej, wskazanemu
            podmiotowi, dane osobowe Użytkownika. Potwierdzenie przez
            Użytkownika żądania jest niezbędne z uwagi na bezpieczeństwo danych
            osobowych Użytkownika oraz uzyskanie pewności, że żądanie pochodzi
            od osoby uprawnionej.
          </p>
          <p>
            8. Administrator informuje Użytkownika o podjętych działaniach,
            przed upływem miesiąca od otrzymania jednego z żądań wymienionych w
            poprzednich punktach.
          </p>
        </div>

        <div>
          <h3>VI. OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH</h3>
          <p>
            1. Zasadniczo dane osobowe są przechowywane tylko tak długo, jak to
            jest konieczne do wypełnienia zobowiązań umownych lub ustawowych,
            dla których zostały one zgromadzone. Dane te zostaną usunięte
            natychmiast, gdy ich przechowywanie nie będzie konieczne, w celach
            dowodowych, zgodnie z prawem cywilnym lub w związku z ustawowym
            obowiązkiem przechowywania danych.
          </p>
          <p>
            2. Informacje dotyczące umowy przechowuje się w celach dowodowych,
            przez okres trzech lat, począwszy od końca roku, w którym zakończono
            relacje handlowe z Użytkownikiem. Usunięcie danych nastąpi po
            upływie ustawowego terminu przedawnienia dochodzenia roszczeń
            umownych.
          </p>
          <p>
            3. Ponadto, administrator może zachować informacje archiwalne,
            dotyczące zawartych transakcji, gdyż ich przechowywanie jest
            związane z przysługującymi Użytkownikowi roszczeniami np. z tytułu
            rękojmi.
          </p>
          <p>
            4. Jeśli żadna umowa nie została zawarta, między Użytkownikiem i
            Właścicielem, dane osobowe Użytkownika są przechowywane do czasu
            usunięcia konta Użytkownika na stronie internetowej. Usunięcie konta
            może nastąpić w wyniku wysunięcia żądania przez Użytkownika,
            wycofania zgody na przetwarzanie danych osobowych, bądź też
            zgłoszenia sprzeciwu co do przetwarzania tych danych.
          </p>
        </div>

        <div>
          <h3>VII. POWIERZENIE PRZETWARZANIA DANYCH INNYM PODMIOTOM</h3>
          <p>
            1. Administrator może powierzać przetwarzanie danych osobowych
            podmiotom współpracującym z administratorem, w zakresie niezbędnym
            dla realizacji transakcji np. w celu przygotowania zamówionego
            towaru oraz dostarczania przesyłek lub przekazywania informacji
            handlowych, pochodzących od administratora (ostatnie dotyczy
            Użytkowników, którzy wyrazili zgodę na otrzymywanie informacji
            handlowych).
          </p>
          <p>
            2. Poza celami wskazanymi w niniejszej Polityce Prywatności, dane
            osobowe Użytkowników, nie będą w żaden sposób udostępniane osobom
            trzecim ani przekazywane innym podmiotom, w celu przesyłania
            materiałów marketingowych tych osób trzecich.
          </p>
          <p>
            3. Dane osobowe Użytkowników strony internetowej nie są przekazywane
            poza obszar Unii Europejskiej.
          </p>
          <p>
            4. Niniejsza Polityka Prywatności jest zgodna z przepisami
            wynikającymi z art. 13 ust. 1 i ust. 2 rozporządzenia RODO.
          </p>
        </div>
      </article>
    </section>
  );
}
